<template>
  <b-container fluid>
    <h3 class="mt-3 mb-2">Endereço</h3>
    <b-row>
      <b-col cols="2">
        <b-form-group>
          <label for="zipcode">CEP</label>
          <b-input
            id="zipcode"
            v-model="form.address.zipCode"
            placeholder="Descrever"
            @input="searchCEP"
            v-mask="'#####-###'"
          />
        </b-form-group>
      </b-col>

      <b-col cols="5">
        <b-form-group>
          <label for="street">Logradouro</label>
          <b-input
            id="street"
            v-model="form.address.street"
            placeholder="Descrever"
          />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <label for="number">Nº</label>
          <b-input
            ref="number"
            id="number"
            v-model="form.address.number"
            placeholder="Descrever"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <b-form-group>
          <label for="number">Complemento</label>
          <b-input
            id="complement"
            v-model="form.address.complement"
            placeholder="Descrever"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="5">
        <b-form-group>
          <label for="neighborhood">Bairro</label>
          <b-input
            id="neighborhood"
            v-model="form.address.neighborhood"
            placeholder="Descrever"
          />
        </b-form-group>
      </b-col>

      <b-col cols="5">
        <b-form-group>
          <label for="city">Cidade</label>
          <b-input
            id="city"
            v-model="form.address.city"
            placeholder="Descrever"
          />
        </b-form-group>
      </b-col>

      <b-col cols="2">
        <b-form-group>
          <label for="state">Estado</label>
          <multiselect
            class="with-border"
            id="state"
            :options="statesOptions"
            v-model="form.address.state"
            :show-labels="false"
            placeholder="Selecionar"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as cep from 'cep-promise'

export default {
  name: 'AddressData',
  props: {
    form: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg')
  },
  data: () => ({
    statesOptions: [
      'AC',
      'AL',
      'AP',
      'AM',
      'BA',
      'CE',
      'DF',
      'ES',
      'GO',
      'MA',
      'MT',
      'MS',
      'MG',
      'PA',
      'PB',
      'PR',
      'PE',
      'PI',
      'RJ',
      'RN',
      'RS',
      'RO',
      'RR',
      'SC',
      'SP',
      'SE',
      'TO'
    ]
  }),
  methods: {
    async searchCEP(data) {
      if (data.length === 9) {
        try {
          const response = await cep(data)
          this.$set(this.form.address, 'street', response.street)
          this.$set(this.form.address, 'neighborhood', response.neighborhood)
          this.$set(this.form.address, 'city', response.city)
          this.$set(this.form.address, 'state', response.state)

          this.$refs.number.focus()
        } catch (error) {
          this.$toast.error('CEP não encontrado')
        }
      }
    }
  }
}
</script>
